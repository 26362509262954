/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const readingsForSensor = /* GraphQL */ `
  query ReadingsForSensor($sensorId: ID!, $limit: Int, $nextToken: String) {
    readingsForSensor(
      sensorId: $sensorId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        treatment {
          id
          tag
          active
          start
          end
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          readings {
            nextToken
            __typename
          }
          murphyTemps {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        error
        readingSensorId
        temp1
        temp2
        temp3
        temp4
        voltage1
        voltage2
        voltage3
        pressure1
        pressure2
        pressure3
        pressure4
        pressure5
        pressure6
        min
        minFull
        min2
        am
        b1Syrup
        b2Syrup
        b3Syrup
        b4Syrup
        b1SyrupOut
        b2SyrupOut
        b3SyrupOut
        b4SyrupOut
        b1Water
        b2Water
        b3Water
        b4Water
        b1Defrost
        b2Defrost
        b3Defrost
        b4Defrost
        b1Liquid
        b2Liquid
        b3Liquid
        b4Liquid
        b1Fill
        b2Fill
        b3Fill
        b4Fill
        b1BIB
        b2BIB
        b3BIB
        b4BIB
        b1C02
        b2C02
        b3C02
        b4C02
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        ambient
        H20Pressure
        C02Pressure
        leftHighPressureCoil
        rightHighPressureCoil
        compSuctionTemp
        compReturnTemp
        compCut
        leftCompContCoil
        rightCompContCoil
        testSignal
        testAcknowledge
        gnd1
        gnd2
        gnd3
        gnd4
        ACReturn
        ACIn
        readingTreatmentId
        session {
          id
          createdAt
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          readings {
            nextToken
            __typename
          }
          product {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          high
          low
          min
          max
          updatedAt
          __typename
        }
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const checkReadings = /* GraphQL */ `
  query CheckReadings(
    $sensorId: String
    $number: Int
    $unit: Unit
    $temp: Int
    $operator: String
    $from: String
    $to: String
    $nextToken: String
  ) {
    checkReadings(
      sensorId: $sensorId
      number: $number
      unit: $unit
      temp: $temp
      operator: $operator
      from: $from
      to: $to
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        min
        minFull
        min2
        temp1
        temp2
        temp3
        temp4
        pressure1
        pressure2
        pressure3
        pressure4
        pressure5
        pressure6
        voltage1
        voltage2
        voltage3
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        readingSensorId
        readingTreatmentId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const checkConnectivity = /* GraphQL */ `
  query CheckConnectivity {
    checkConnectivity {
      id
      name
      label
      online
      lastCheckIn
      lastReading {
        id
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        treatment {
          id
          tag
          active
          start
          end
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          readings {
            nextToken
            __typename
          }
          murphyTemps {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        error
        readingSensorId
        temp1
        temp2
        temp3
        temp4
        voltage1
        voltage2
        voltage3
        pressure1
        pressure2
        pressure3
        pressure4
        pressure5
        pressure6
        min
        minFull
        min2
        am
        b1Syrup
        b2Syrup
        b3Syrup
        b4Syrup
        b1SyrupOut
        b2SyrupOut
        b3SyrupOut
        b4SyrupOut
        b1Water
        b2Water
        b3Water
        b4Water
        b1Defrost
        b2Defrost
        b3Defrost
        b4Defrost
        b1Liquid
        b2Liquid
        b3Liquid
        b4Liquid
        b1Fill
        b2Fill
        b3Fill
        b4Fill
        b1BIB
        b2BIB
        b3BIB
        b4BIB
        b1C02
        b2C02
        b3C02
        b4C02
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        ambient
        H20Pressure
        C02Pressure
        leftHighPressureCoil
        rightHighPressureCoil
        compSuctionTemp
        compReturnTemp
        compCut
        leftCompContCoil
        rightCompContCoil
        testSignal
        testAcknowledge
        gnd1
        gnd2
        gnd3
        gnd4
        ACReturn
        ACIn
        readingTreatmentId
        session {
          id
          createdAt
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          readings {
            nextToken
            __typename
          }
          product {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          high
          low
          min
          max
          updatedAt
          __typename
        }
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const checkTTNSensor = /* GraphQL */ `
  query CheckTTNSensor($devEui: String) {
    checkTTNSensor(devEui: $devEui)
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      name
      address
      city
      state
      zip
      code
      owner {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      users {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      store {
        id
        name
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      admins {
        items {
          id
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        address
        city
        state
        zip
        code
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        users {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStore = /* GraphQL */ `
  query GetStore($id: ID!) {
    getStore(id: $id) {
      id
      name
      distributor {
        id
        name
        owners {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        stores {
          items {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      locations {
        items {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      admins {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listStores = /* GraphQL */ `
  query ListStores(
    $filter: ModelStoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStores(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStock = /* GraphQL */ `
  query GetStock($devEui: String!) {
    getStock(devEui: $devEui) {
      devEui
      owner {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      brand
      model
      firmware
      sn
      daddr
      profile
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listStocks = /* GraphQL */ `
  query ListStocks(
    $devEui: String
    $filter: ModelStockFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStocks(
      devEui: $devEui
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        devEui
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        brand
        model
        firmware
        sn
        daddr
        profile
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGateway = /* GraphQL */ `
  query GetGateway($devEui: String!) {
    getGateway(devEui: $devEui) {
      devEui
      connected
      lastCheckIn
      lastPacket
      firstConnect
      brand
      model
      sn
      owner {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      gatewayOwnerId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGateways = /* GraphQL */ `
  query ListGateways(
    $devEui: String
    $filter: ModelGatewayFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGateways(
      devEui: $devEui
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        devEui
        connected
        lastCheckIn
        lastPacket
        firstConnect
        brand
        model
        sn
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gatewayOwnerId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      first
      last
      email
      phone
      code
      approved
      distributor {
        id
        name
        owners {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        stores {
          items {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      store {
        id
        name
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      auth
      location {
        id
        name
        address
        city
        state
        zip
        code
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        users {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      locations {
        items {
          id
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      admin {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      admins {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      subs {
        items {
          id
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sub {
            id
            topic
            deviceId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      type
      status
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      fuelCells {
        items {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensorActive
      payment
      business
      degreePref
      resolution
      gateways {
        items {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDistributor = /* GraphQL */ `
  query GetDistributor($id: ID!) {
    getDistributor(id: $id) {
      id
      name
      owners {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      stores {
        items {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDistributors = /* GraphQL */ `
  query ListDistributors(
    $filter: ModelDistributorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDistributors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        owners {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        stores {
          items {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDevice = /* GraphQL */ `
  query GetDevice($id: ID!) {
    getDevice(id: $id) {
      id
      model
      manufacturer
      test
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDevices = /* GraphQL */ `
  query ListDevices(
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        model
        manufacturer
        test
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getReading = /* GraphQL */ `
  query GetReading($id: ID!, $createdAt: AWSDateTime!) {
    getReading(id: $id, createdAt: $createdAt) {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      treatment {
        id
        tag
        active
        start
        end
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        murphyTemps {
          items {
            id
            temp
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      error
      readingSensorId
      temp1
      temp2
      temp3
      temp4
      voltage1
      voltage2
      voltage3
      pressure1
      pressure2
      pressure3
      pressure4
      pressure5
      pressure6
      min
      minFull
      min2
      am
      b1Syrup
      b2Syrup
      b3Syrup
      b4Syrup
      b1SyrupOut
      b2SyrupOut
      b3SyrupOut
      b4SyrupOut
      b1Water
      b2Water
      b3Water
      b4Water
      b1Defrost
      b2Defrost
      b3Defrost
      b4Defrost
      b1Liquid
      b2Liquid
      b3Liquid
      b4Liquid
      b1Fill
      b2Fill
      b3Fill
      b4Fill
      b1BIB
      b2BIB
      b3BIB
      b4BIB
      b1C02
      b2C02
      b3C02
      b4C02
      b1Temp
      b2Temp
      b3Temp
      b4Temp
      ambient
      H20Pressure
      C02Pressure
      leftHighPressureCoil
      rightHighPressureCoil
      compSuctionTemp
      compReturnTemp
      compCut
      leftCompContCoil
      rightCompContCoil
      testSignal
      testAcknowledge
      gnd1
      gnd2
      gnd3
      gnd4
      ACReturn
      ACIn
      readingTreatmentId
      session {
        id
        createdAt
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        product {
          id
          name
          high
          low
          degreePref
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          sessions {
            nextToken
            __typename
          }
          type
          min
          max
          createdAt
          updatedAt
          __typename
        }
        high
        low
        min
        max
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const listReadings = /* GraphQL */ `
  query ListReadings(
    $id: ID
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelReadingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listReadings(
      id: $id
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        treatment {
          id
          tag
          active
          start
          end
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          readings {
            nextToken
            __typename
          }
          murphyTemps {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        error
        readingSensorId
        temp1
        temp2
        temp3
        temp4
        voltage1
        voltage2
        voltage3
        pressure1
        pressure2
        pressure3
        pressure4
        pressure5
        pressure6
        min
        minFull
        min2
        am
        b1Syrup
        b2Syrup
        b3Syrup
        b4Syrup
        b1SyrupOut
        b2SyrupOut
        b3SyrupOut
        b4SyrupOut
        b1Water
        b2Water
        b3Water
        b4Water
        b1Defrost
        b2Defrost
        b3Defrost
        b4Defrost
        b1Liquid
        b2Liquid
        b3Liquid
        b4Liquid
        b1Fill
        b2Fill
        b3Fill
        b4Fill
        b1BIB
        b2BIB
        b3BIB
        b4BIB
        b1C02
        b2C02
        b3C02
        b4C02
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        ambient
        H20Pressure
        C02Pressure
        leftHighPressureCoil
        rightHighPressureCoil
        compSuctionTemp
        compReturnTemp
        compCut
        leftCompContCoil
        rightCompContCoil
        testSignal
        testAcknowledge
        gnd1
        gnd2
        gnd3
        gnd4
        ACReturn
        ACIn
        readingTreatmentId
        session {
          id
          createdAt
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          readings {
            nextToken
            __typename
          }
          product {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          high
          low
          min
          max
          updatedAt
          __typename
        }
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFuelCell = /* GraphQL */ `
  query GetFuelCell($name: String!) {
    getFuelCell(name: $name) {
      name
      treatments {
        items {
          id
          tag
          active
          start
          end
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          readings {
            nextToken
            __typename
          }
          murphyTemps {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      online
      lastUpdate
      fuel
      water
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      tunnel
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFuelCells = /* GraphQL */ `
  query ListFuelCells(
    $name: String
    $filter: ModelFuelCellFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFuelCells(
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        name
        treatments {
          items {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        online
        lastUpdate
        fuel
        water
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        tunnel
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTreatment = /* GraphQL */ `
  query GetTreatment($id: ID!) {
    getTreatment(id: $id) {
      id
      tag
      active
      start
      end
      fuelCell {
        name
        treatments {
          items {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        online
        lastUpdate
        fuel
        water
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        tunnel
        createdAt
        updatedAt
        __typename
      }
      readings {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          treatment {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          createdAt
          error
          readingSensorId
          temp1
          temp2
          temp3
          temp4
          voltage1
          voltage2
          voltage3
          pressure1
          pressure2
          pressure3
          pressure4
          pressure5
          pressure6
          min
          minFull
          min2
          am
          b1Syrup
          b2Syrup
          b3Syrup
          b4Syrup
          b1SyrupOut
          b2SyrupOut
          b3SyrupOut
          b4SyrupOut
          b1Water
          b2Water
          b3Water
          b4Water
          b1Defrost
          b2Defrost
          b3Defrost
          b4Defrost
          b1Liquid
          b2Liquid
          b3Liquid
          b4Liquid
          b1Fill
          b2Fill
          b3Fill
          b4Fill
          b1BIB
          b2BIB
          b3BIB
          b4BIB
          b1C02
          b2C02
          b3C02
          b4C02
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          ambient
          H20Pressure
          C02Pressure
          leftHighPressureCoil
          rightHighPressureCoil
          compSuctionTemp
          compReturnTemp
          compCut
          leftCompContCoil
          rightCompContCoil
          testSignal
          testAcknowledge
          gnd1
          gnd2
          gnd3
          gnd4
          ACReturn
          ACIn
          readingTreatmentId
          session {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      murphyTemps {
        items {
          id
          temp
          treatment {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTreatments = /* GraphQL */ `
  query ListTreatments(
    $filter: ModelTreatmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTreatments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tag
        active
        start
        end
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        murphyTemps {
          items {
            id
            temp
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTemp = /* GraphQL */ `
  query GetTemp($id: ID!) {
    getTemp(id: $id) {
      id
      temp
      treatment {
        id
        tag
        active
        start
        end
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        murphyTemps {
          items {
            id
            temp
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTemps = /* GraphQL */ `
  query ListTemps(
    $filter: ModelTempFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTemps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        temp
        treatment {
          id
          tag
          active
          start
          end
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          readings {
            nextToken
            __typename
          }
          murphyTemps {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSensor = /* GraphQL */ `
  query GetSensor($id: ID!) {
    getSensor(id: $id) {
      id
      uuid
      label
      name
      duel
      duelId
      serialNumber
      oem
      harnessNumber
      partNumber
      development
      downlink
      test
      version
      online
      currentTemp
      currentTemp2
      currentTempFull
      certified
      t1
      t2
      t3
      t4
      v1
      v2
      v3
      p1
      p2
      p3
      p4
      p5
      p6
      b1Temp
      b2Temp
      b3Temp
      b4Temp
      compSuctionTemp
      compReturnTemp
      ambient
      displayValues
      code
      fuelCell {
        name
        treatments {
          items {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        online
        lastUpdate
        fuel
        water
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        tunnel
        createdAt
        updatedAt
        __typename
      }
      recording
      location {
        id
        name
        address
        city
        state
        zip
        code
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        users {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      locationNote
      sensorLocationId
      device {
        id
        model
        manufacturer
        test
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      sensorDeviceId
      sensorUserId
      rssi
      updateInterval
      readings {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          treatment {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          createdAt
          error
          readingSensorId
          temp1
          temp2
          temp3
          temp4
          voltage1
          voltage2
          voltage3
          pressure1
          pressure2
          pressure3
          pressure4
          pressure5
          pressure6
          min
          minFull
          min2
          am
          b1Syrup
          b2Syrup
          b3Syrup
          b4Syrup
          b1SyrupOut
          b2SyrupOut
          b3SyrupOut
          b4SyrupOut
          b1Water
          b2Water
          b3Water
          b4Water
          b1Defrost
          b2Defrost
          b3Defrost
          b4Defrost
          b1Liquid
          b2Liquid
          b3Liquid
          b4Liquid
          b1Fill
          b2Fill
          b3Fill
          b4Fill
          b1BIB
          b2BIB
          b3BIB
          b4BIB
          b1C02
          b2C02
          b3C02
          b4C02
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          ambient
          H20Pressure
          C02Pressure
          leftHighPressureCoil
          rightHighPressureCoil
          compSuctionTemp
          compReturnTemp
          compCut
          leftCompContCoil
          rightCompContCoil
          testSignal
          testAcknowledge
          gnd1
          gnd2
          gnd3
          gnd4
          ACReturn
          ACIn
          readingTreatmentId
          session {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tags {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          tag {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      active
      alert
      alarm
      high
      low
      highC
      lowC
      email
      phone
      time
      raw
      duration
      monitor
      battery
      humidity
      leak
      subs {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          sub {
            id
            topic
            deviceId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notes
      lastCheckIn
      alarms {
        items {
          id
          input
          value
          valueC
          predicate
          duration
          active
          ack
          pause
          default
          start
          stop
          type
          timezone
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          alarmSensorId
          events {
            nextToken
            __typename
          }
          activeDays
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notifications {
        items {
          id
          type
          info
          active
          default
          pause
          nickname
          log {
            nextToken
            __typename
          }
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          notificationSensorId
          messages {
            nextToken
            __typename
          }
          events {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      customer {
        id
        first
        last
        email
        phone
        approved
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      events {
        items {
          id
          createdAt
          alarm {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          logs {
            nextToken
            __typename
          }
          messages {
            nextToken
            __typename
          }
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          resolved
          status
          result
          notify
          notifications {
            nextToken
            __typename
          }
          notes
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      logs {
        items {
          id
          createdAt
          alarm
          alerts
          logSensorId
          type
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          trigger
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          notification {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reports {
        items {
          id
          createdAt
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          period
          reportSensorId
          periodStartTimestamp
          highTemps
          lowTemps
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportingEnabled
      andrewTest
      validated
      timezone
      degreePref
      sessions {
        items {
          id
          createdAt
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          readings {
            nextToken
            __typename
          }
          product {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          high
          low
          min
          max
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      products {
        items {
          id
          name
          high
          low
          degreePref
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          sessions {
            nextToken
            __typename
          }
          type
          min
          max
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      gateway {
        devEui
        connected
        lastCheckIn
        lastPacket
        firstConnect
        brand
        model
        sn
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gatewayOwnerId
        createdAt
        updatedAt
        __typename
      }
      sensorGatewayId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSensors = /* GraphQL */ `
  query ListSensors(
    $filter: ModelSensorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSensors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      createdAt
      alarm {
        id
        input
        value
        valueC
        predicate
        duration
        active
        ack
        pause
        default
        start
        stop
        type
        timezone
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        alarmSensorId
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        activeDays
        createdAt
        updatedAt
        __typename
      }
      logs {
        items {
          id
          createdAt
          alarm
          alerts
          logSensorId
          type
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          trigger
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          notification {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messages {
        items {
          id
          alert {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          responseBy
          userResponse
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      resolved
      status
      result
      notify
      notifications {
        items {
          id
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          notification {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notes
      updatedAt
      __typename
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        alarm {
          id
          input
          value
          valueC
          predicate
          duration
          active
          ack
          pause
          default
          start
          stop
          type
          timezone
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          alarmSensorId
          events {
            nextToken
            __typename
          }
          activeDays
          createdAt
          updatedAt
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        resolved
        status
        result
        notify
        notifications {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLog = /* GraphQL */ `
  query GetLog($id: ID!) {
    getLog(id: $id) {
      id
      createdAt
      alarm
      alerts
      logSensorId
      type
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      trigger
      event {
        id
        createdAt
        alarm {
          id
          input
          value
          valueC
          predicate
          duration
          active
          ack
          pause
          default
          start
          stop
          type
          timezone
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          alarmSensorId
          events {
            nextToken
            __typename
          }
          activeDays
          createdAt
          updatedAt
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        resolved
        status
        result
        notify
        notifications {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        updatedAt
        __typename
      }
      notification {
        id
        type
        info
        active
        default
        pause
        nickname
        log {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        notificationSensorId
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const listLogs = /* GraphQL */ `
  query ListLogs(
    $filter: ModelLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        alarm
        alerts
        logSensorId
        type
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        trigger
        event {
          id
          createdAt
          alarm {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          logs {
            nextToken
            __typename
          }
          messages {
            nextToken
            __typename
          }
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          resolved
          status
          result
          notify
          notifications {
            nextToken
            __typename
          }
          notes
          updatedAt
          __typename
        }
        notification {
          id
          type
          info
          active
          default
          pause
          nickname
          log {
            nextToken
            __typename
          }
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          notificationSensorId
          messages {
            nextToken
            __typename
          }
          events {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      first
      last
      email
      phone
      approved
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        first
        last
        email
        phone
        approved
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAlarm = /* GraphQL */ `
  query GetAlarm($id: ID!) {
    getAlarm(id: $id) {
      id
      input
      value
      valueC
      predicate
      duration
      active
      ack
      pause
      default
      start
      stop
      type
      timezone
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      alarmSensorId
      events {
        items {
          id
          createdAt
          alarm {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          logs {
            nextToken
            __typename
          }
          messages {
            nextToken
            __typename
          }
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          resolved
          status
          result
          notify
          notifications {
            nextToken
            __typename
          }
          notes
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      activeDays
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAlarms = /* GraphQL */ `
  query ListAlarms(
    $filter: ModelAlarmFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlarms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        input
        value
        valueC
        predicate
        duration
        active
        ack
        pause
        default
        start
        stop
        type
        timezone
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        alarmSensorId
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        activeDays
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCanBus = /* GraphQL */ `
  query GetCanBus($id: ID!) {
    getCanBus(id: $id) {
      id
      code
      value
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCanBuss = /* GraphQL */ `
  query ListCanBuss(
    $filter: ModelCanBusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCanBuss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        value
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      type
      info
      active
      default
      pause
      nickname
      log {
        items {
          id
          createdAt
          alarm
          alerts
          logSensorId
          type
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          trigger
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          notification {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      notificationSensorId
      messages {
        items {
          id
          alert {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          responseBy
          userResponse
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      events {
        items {
          id
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          notification {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        info
        active
        default
        pause
        nickname
        log {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        notificationSensorId
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEventNotification = /* GraphQL */ `
  query GetEventNotification($id: ID!) {
    getEventNotification(id: $id) {
      id
      event {
        id
        createdAt
        alarm {
          id
          input
          value
          valueC
          predicate
          duration
          active
          ack
          pause
          default
          start
          stop
          type
          timezone
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          alarmSensorId
          events {
            nextToken
            __typename
          }
          activeDays
          createdAt
          updatedAt
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        resolved
        status
        result
        notify
        notifications {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        updatedAt
        __typename
      }
      notification {
        id
        type
        info
        active
        default
        pause
        nickname
        log {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        notificationSensorId
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEventNotifications = /* GraphQL */ `
  query ListEventNotifications(
    $filter: ModelEventNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        event {
          id
          createdAt
          alarm {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          logs {
            nextToken
            __typename
          }
          messages {
            nextToken
            __typename
          }
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          resolved
          status
          result
          notify
          notifications {
            nextToken
            __typename
          }
          notes
          updatedAt
          __typename
        }
        notification {
          id
          type
          info
          active
          default
          pause
          nickname
          log {
            nextToken
            __typename
          }
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          notificationSensorId
          messages {
            nextToken
            __typename
          }
          events {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAdminLocation = /* GraphQL */ `
  query GetAdminLocation($id: ID!) {
    getAdminLocation(id: $id) {
      id
      admin {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      location {
        id
        name
        address
        city
        state
        zip
        code
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        users {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAdminLocations = /* GraphQL */ `
  query ListAdminLocations(
    $filter: ModelAdminLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      alert {
        id
        type
        info
        active
        default
        pause
        nickname
        log {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        notificationSensorId
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      event {
        id
        createdAt
        alarm {
          id
          input
          value
          valueC
          predicate
          duration
          active
          ack
          pause
          default
          start
          stop
          type
          timezone
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          alarmSensorId
          events {
            nextToken
            __typename
          }
          activeDays
          createdAt
          updatedAt
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        resolved
        status
        result
        notify
        notifications {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        updatedAt
        __typename
      }
      responseBy
      userResponse
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        alert {
          id
          type
          info
          active
          default
          pause
          nickname
          log {
            nextToken
            __typename
          }
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          notificationSensorId
          messages {
            nextToken
            __typename
          }
          events {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        event {
          id
          createdAt
          alarm {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          logs {
            nextToken
            __typename
          }
          messages {
            nextToken
            __typename
          }
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          resolved
          status
          result
          notify
          notifications {
            nextToken
            __typename
          }
          notes
          updatedAt
          __typename
        }
        responseBy
        userResponse
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCode = /* GraphQL */ `
  query GetCode($code: String!) {
    getCode(code: $code) {
      code
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCodes = /* GraphQL */ `
  query ListCodes(
    $code: String
    $filter: ModelCodeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCodes(
      code: $code
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        code
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      name
      sensors {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          tag {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        sensors {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSensorTag = /* GraphQL */ `
  query GetSensorTag($id: ID!) {
    getSensorTag(id: $id) {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      tag {
        id
        name
        sensors {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSensorTags = /* GraphQL */ `
  query ListSensorTags(
    $filter: ModelSensorTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSensorTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        tag {
          id
          name
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSub = /* GraphQL */ `
  query GetSub($id: ID!) {
    getSub(id: $id) {
      id
      topic
      deviceId
      users {
        items {
          id
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sub {
            id
            topic
            deviceId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensors {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          sub {
            id
            topic
            deviceId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSubs = /* GraphQL */ `
  query ListSubs(
    $filter: ModelSubFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        topic
        deviceId
        users {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensors {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserSub = /* GraphQL */ `
  query GetUserSub($id: ID!) {
    getUserSub(id: $id) {
      id
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      sub {
        id
        topic
        deviceId
        users {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensors {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserSubs = /* GraphQL */ `
  query ListUserSubs(
    $filter: ModelUserSubFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserSubs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sub {
          id
          topic
          deviceId
          users {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSensorSub = /* GraphQL */ `
  query GetSensorSub($id: ID!) {
    getSensorSub(id: $id) {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      sub {
        id
        topic
        deviceId
        users {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensors {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSensorSubs = /* GraphQL */ `
  query ListSensorSubs(
    $filter: ModelSensorSubFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSensorSubs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        sub {
          id
          topic
          deviceId
          users {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSession = /* GraphQL */ `
  query GetSession($id: ID!) {
    getSession(id: $id) {
      id
      createdAt
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      readings {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          treatment {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          createdAt
          error
          readingSensorId
          temp1
          temp2
          temp3
          temp4
          voltage1
          voltage2
          voltage3
          pressure1
          pressure2
          pressure3
          pressure4
          pressure5
          pressure6
          min
          minFull
          min2
          am
          b1Syrup
          b2Syrup
          b3Syrup
          b4Syrup
          b1SyrupOut
          b2SyrupOut
          b3SyrupOut
          b4SyrupOut
          b1Water
          b2Water
          b3Water
          b4Water
          b1Defrost
          b2Defrost
          b3Defrost
          b4Defrost
          b1Liquid
          b2Liquid
          b3Liquid
          b4Liquid
          b1Fill
          b2Fill
          b3Fill
          b4Fill
          b1BIB
          b2BIB
          b3BIB
          b4BIB
          b1C02
          b2C02
          b3C02
          b4C02
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          ambient
          H20Pressure
          C02Pressure
          leftHighPressureCoil
          rightHighPressureCoil
          compSuctionTemp
          compReturnTemp
          compCut
          leftCompContCoil
          rightCompContCoil
          testSignal
          testAcknowledge
          gnd1
          gnd2
          gnd3
          gnd4
          ACReturn
          ACIn
          readingTreatmentId
          session {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      product {
        id
        name
        high
        low
        degreePref
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        min
        max
        createdAt
        updatedAt
        __typename
      }
      high
      low
      min
      max
      updatedAt
      __typename
    }
  }
`;
export const listSessions = /* GraphQL */ `
  query ListSessions(
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        product {
          id
          name
          high
          low
          degreePref
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          sessions {
            nextToken
            __typename
          }
          type
          min
          max
          createdAt
          updatedAt
          __typename
        }
        high
        low
        min
        max
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      name
      high
      low
      degreePref
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      sessions {
        items {
          id
          createdAt
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          readings {
            nextToken
            __typename
          }
          product {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          high
          low
          min
          max
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      type
      min
      max
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        high
        low
        degreePref
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        min
        max
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getReport = /* GraphQL */ `
  query GetReport($id: ID!, $periodStartTimestamp: Int!) {
    getReport(id: $id, periodStartTimestamp: $periodStartTimestamp) {
      id
      createdAt
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      period
      reportSensorId
      periodStartTimestamp
      highTemps
      lowTemps
      updatedAt
      __typename
    }
  }
`;
export const listReports = /* GraphQL */ `
  query ListReports(
    $id: ID
    $periodStartTimestamp: ModelIntKeyConditionInput
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listReports(
      id: $id
      periodStartTimestamp: $periodStartTimestamp
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        createdAt
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        period
        reportSensorId
        periodStartTimestamp
        highTemps
        lowTemps
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const locationByCode = /* GraphQL */ `
  query LocationByCode(
    $code: String
    $sortDirection: ModelSortDirection
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    locationByCode(
      code: $code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        address
        city
        state
        zip
        code
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        users {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userByCode = /* GraphQL */ `
  query UserByCode(
    $code: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByCode(
      code: $code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSortedSensorReadings = /* GraphQL */ `
  query GetSortedSensorReadings(
    $readingSensorId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReadingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSortedSensorReadings(
      readingSensorId: $readingSensorId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        treatment {
          id
          tag
          active
          start
          end
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          readings {
            nextToken
            __typename
          }
          murphyTemps {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        error
        readingSensorId
        temp1
        temp2
        temp3
        temp4
        voltage1
        voltage2
        voltage3
        pressure1
        pressure2
        pressure3
        pressure4
        pressure5
        pressure6
        min
        minFull
        min2
        am
        b1Syrup
        b2Syrup
        b3Syrup
        b4Syrup
        b1SyrupOut
        b2SyrupOut
        b3SyrupOut
        b4SyrupOut
        b1Water
        b2Water
        b3Water
        b4Water
        b1Defrost
        b2Defrost
        b3Defrost
        b4Defrost
        b1Liquid
        b2Liquid
        b3Liquid
        b4Liquid
        b1Fill
        b2Fill
        b3Fill
        b4Fill
        b1BIB
        b2BIB
        b3BIB
        b4BIB
        b1C02
        b2C02
        b3C02
        b4C02
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        ambient
        H20Pressure
        C02Pressure
        leftHighPressureCoil
        rightHighPressureCoil
        compSuctionTemp
        compReturnTemp
        compCut
        leftCompContCoil
        rightCompContCoil
        testSignal
        testAcknowledge
        gnd1
        gnd2
        gnd3
        gnd4
        ACReturn
        ACIn
        readingTreatmentId
        session {
          id
          createdAt
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          readings {
            nextToken
            __typename
          }
          product {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          high
          low
          min
          max
          updatedAt
          __typename
        }
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const sensorByUUID = /* GraphQL */ `
  query SensorByUUID(
    $uuid: String
    $sortDirection: ModelSortDirection
    $filter: ModelSensorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sensorByUUID(
      uuid: $uuid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const alarmsBySensor = /* GraphQL */ `
  query AlarmsBySensor(
    $alarmSensorId: String
    $sortDirection: ModelSortDirection
    $filter: ModelAlarmFilterInput
    $limit: Int
    $nextToken: String
  ) {
    alarmsBySensor(
      alarmSensorId: $alarmSensorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        input
        value
        valueC
        predicate
        duration
        active
        ack
        pause
        default
        start
        stop
        type
        timezone
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        alarmSensorId
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        activeDays
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const notificationsBySensor = /* GraphQL */ `
  query NotificationsBySensor(
    $notificationSensorId: String
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsBySensor(
      notificationSensorId: $notificationSensorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        info
        active
        default
        pause
        nickname
        log {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        notificationSensorId
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSortedSensorReports = /* GraphQL */ `
  query GetSortedSensorReports(
    $reportSensorId: String
    $periodStartTimestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSortedSensorReports(
      reportSensorId: $reportSensorId
      periodStartTimestamp: $periodStartTimestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        period
        reportSensorId
        periodStartTimestamp
        highTemps
        lowTemps
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
